import AvatarWithStatus from "@/components/AvatarWithStatus";
import NoValidWorkspacesModal from "@/components/NoValidWorkspacesModal";
import Stepper from "@/components/Stepper";
import { FullInput, FullTooltip, StyledChipsInput } from "@/components/Utils";
import { useElectric } from "@/electric/ElectricWrapper";
import ModalForm from "@/elements/ModalForm";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { AppContext } from "@/models/AppStateProvider";
import { DataContext } from "@/models/DataProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { PhoneService } from "@/utils";
import { Close, Help } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { Workspace, WsFeed } from "web-client/api/data-contracts";

export default function Onboarding() {
  const { allowNewWorkspaceCreation } = useFlags();
  const { flags, redirectPath, client } = useContext(AppContext);
  const { workspaceMemberships } = useContext(WorkspaceContext);
  const { myAccount } = useContext(MyAccountContext);
  const { createWorkspace, sendWorkspaceInvites, createWorkspaceFeed } =
    useContext(ActionContext);
  const { ampli } = React.useContext(TrackingContext);
  const navigate = useNavigate();
  const { db } = useElectric();


  const firstWorkspace =
    workspaceMemberships?.length > 0
      ? `/workspaces/${workspaceMemberships[0]?.workspaceId}`
      : false;

  const updateUserInfo = async ({ fullName }) => {
    await db.account.update({
      where: {
        id: myAccount.id,
      },
      data: {
        name: fullName,
      },
    });
    await client.updateAccount(myAccount?.id, {
      fullName: fullName,
    });
  };

  const defaultStatusState = [
    { active: true, step: 1 },
    { active: false, step: 2 },
    { active: false, step: 3 },
    { active: false, step: 4 },
  ];
  const open = true;
  const phoneService = PhoneService();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>();
  const [fullName, setFullName] = useState<string>("");
  const [newWorkspace, setNewWorkspace] = useState<Workspace>();
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [status, setStatus] = useState(defaultStatusState);
  const [invitesSkipped, setInvitesSkipped] = useState<boolean>(false);
  const [newChannelName, setNewChannelName] = useState("");
  const [readOnlyFeed, setReadOnlyFeed] = useState(false);
  const [contacts, setContacts] = useState<string[]>([]);
  const defaultHelperText = "Press enter to complete your entry";
  const [contactHelperText, setContactHelperText] =
    useState<string>(defaultHelperText);
  const invitesCount = contacts?.length;
  const emails = contacts?.filter((contact) => isEmail(contact));
  const phoneNumbers = contacts
    ?.filter((contact) => phoneService?.isValidNumber(contact))
    .map((number) => {
      //if a number doesn't contain a country code, then default to US
      if (!number.includes("+")) {
        return `+1${number}`;
      }
      return number;
    });
  const [openTooltip, setTooltipOpen] = useState(false);
  const [isPrivate, setIsPrivate] = useState<boolean>(true);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const emailsRef = useRef<string[]>([]);

  const mailErrorText = "Something went wrong, please try again later.";

  const initial = React.useMemo(() => fullName[0], [fullName]);

  const isFullNameValid = fullName?.replace(/ /g, "")?.length > 0;
  const isWorkspaceNameValid = workspaceName?.replace(/ /g, "")?.length > 0;

  // check if the redirect path has a specified feed we should redirect to
  // i.e. /workspaces/workspaceId/feeds/clkk17h8j00003b70xnvtvhgg
  const shouldRedirect = /[workspaces]+\/(.+)\/[feeds]+\/(.+)/.test(
    redirectPath,
  );

  const url = `${window.location.origin}${`/workspaces/${newWorkspace}`}`;
  const currentActiveState = status.filter((item) => item.active).length;

  useEffect(() => {
    if (myAccount?.name) {
      setFullName(myAccount.name);
    }
  }, [myAccount]);

  useEffect(() => {
    if (isFullNameValid) {
      setNewChannelName(() => `${fullName}'s Channel`);
    }
  }, [fullName, isFullNameValid]);

  const theme = useTheme();

  const saveFullName = async () => {
    setError(false);
    if (!fullName || !isFullNameValid) {
      setError(true);
    }
    try {
      setSubmitting(true);
      await updateUserInfo({ fullName });
      ampli.submitFullName();
      return false;
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
      //if we have a url to redirect too then all we need to collect is the name
      if (
        firstWorkspace ||
        (workspaceMemberships.length > 0 && shouldRedirect)
      ) {
        handleDone();
      } else {
        nextStep();
      }
    }
  };

  const handleWorkspaceCreation = async () => {
    let newWs: Workspace;
    try {
      if (isWorkspaceNameValid) {
        setSubmitting(true);
        ampli.createWorkspace();
        newWs = await createWorkspace(workspaceName);
      }
    } catch (e) {
      setError(true);
      console.log(e);
      return Promise.reject(e);
    } finally {
      setError(false);
      setNewWorkspace(() => newWs);
      setSubmitting(false);
      nextStep();
    }
  };

  const validateContact = (contact: string) => {
    if (isEmail(contact) || phoneService.isValidNumber(contact)) {
      // valid input
      return { isError: false, textError: "" };
    } else {
      // invalid input
      return { isError: true, textError: "Invalid email or phone input" };
    }
  };

  const handleContactHelperText = useCallback(
    (node) => {
      if (invitesCount > 0 && node?.value === "") {
        setContactHelperText("Double click to edit a contact");
      } else {
        setContactHelperText(defaultHelperText);
      }
    },
    [invitesCount],
  );

  const handleContactsChange = (values: string[]) => {
    console.log("** handleContactsChange", values);
    setContacts(values);
  };

  const handleInviteClick = async () => {
    if (contacts.length) {
      setSubmitting(true);
      setError(false);
      setErrorText("");
      try {
        await sendWorkspaceInvites(
          newWorkspace?.id,
          emails || [],
          phoneNumbers || [],
        );
        console.warn("** mail/invite/success");
        setContacts([]);
      } catch (e) {
        console.warn("** ERR ERR mail/invite/click", e);
        setError(true);
        setErrorText(mailErrorText);
        return Promise.reject(e);
      } finally {
        setSubmitting(false);
        nextStep();
      }
    }
  };

  const handleInviteSkip = () => {
    setInvitesSkipped(true);
  };

  const handleInviteSkipConfirm = () => {
    nextStep();
  };

  const handleInviteSkipCancel = () => {
    setInvitesSkipped(false);
  };

  const changeChannelType = (value) => {
    setIsPrivate(() => value);
  };

  const toggleReadOnly = () => setReadOnlyFeed((value) => !value);

  const handleCreateWorkspaceFeed = async () => {
    let newWorkspaceFeed: WsFeed;
    try {
      // disable the button while creating a feed
      setSubmitting(true);
      newWorkspaceFeed = await createWorkspaceFeed(
        newWorkspace.id,
        newChannelName,
        isPrivate,
        readOnlyFeed,
      );

      ampli.feedCreate({
        teamName: newChannelName,
        feedId: newWorkspaceFeed.id,
        listenOnly: readOnlyFeed,
      });
    } catch (e) {
      setError(true);
      return Promise.reject(e);
    } finally {
      setNewChannelName("");
      setSubmitting(false);
      if (newWorkspaceFeed) {
        navigate(
          `/workspaces/${newWorkspace?.id}/feeds/${newWorkspaceFeed.id}`,
        );
      }
    }
  };

  const prevStep = () => {
    if (currentActiveState - 1 === 0) {
      return;
    }
    setStatus((oldVal) => {
      oldVal[currentActiveState - 1].active = false;
      return [...oldVal];
    });
  };

  const nextStep = () => {
    if (defaultStatusState.length === currentActiveState) {
      return;
    }
    setStatus((oldVal) => {
      oldVal[currentActiveState].active = true;
      return [...oldVal];
    });
  };

  const handleDone = () => {
    ampli.completeOnboardingDone();
    if (firstWorkspace) {
      window.location.replace(firstWorkspace);
      return;
    }
    if (redirectPath && shouldRedirect) {
      window.location.replace(redirectPath);
    } else {
      if (url) {
        window.location.replace(url);
      }
    }
  };

  const showSteps = useMemo(
    () => workspaceMemberships?.length === 0 && !shouldRedirect,
    [shouldRedirect, workspaceMemberships],
  );

  const ChannelNameLabel = () => {
    const displayTitle = (
      <Stack sx={{ gap: 1 }}>
        <Typography sx={{ fontSize: "1.25rem", fontWeight: 700 }}>
          Naming a channel
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          When naming a channel, it is best to be specific and brief, so that
          the names are easy to recall and say when using voice commands. Avoid
          using special characters or emojis in your channel name.
        </Typography>
      </Stack>
    );

    return (
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          gap: 1,
        }}
      >
        <Typography>Channel name</Typography>
        <FullTooltip
          describeChild
          title={displayTitle}
          placement="bottom-start"
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          slotProps={{
            tooltip: {
              sx: {
                "&.MuiTooltip-tooltip": {
                  padding: 2.25,
                  width: "100%",
                  maxWidth: 400,
                },
              },
            },
            popper: {
              sx: {
                '&.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip':
                {
                  mt: 1,
                },
              },
            },
          }}
        >
          <IconButton
            sx={{
              p: 0,
              borderRadius: "1000px",
              "&:hover:not([disabled])": {
                background: "transparent",
              },
            }}
          >
            <Help
              role="img"
              sx={{
                fontSize: "1.25rem",
                color: openTooltip
                  ? theme.palette.info.light
                  : theme.palette.primary.main,
              }}
            />
          </IconButton>
        </FullTooltip>
      </Stack>
    );
  };

  const channelNameCharLimit = 40;

  const ChannelNameHelperText = ({ characterLimit, field }) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 1,
          width: "100%",
        }}
      >
        <Typography>
          {field?.length}/{characterLimit}
        </Typography>
      </Box>
    );
  };

  const ListenOnlyMode = () => {
    return (
      <Box sx={{ mt: 1, pl: 2.5 }}>
        <Typography
          sx={{
            fontWeight: 500,
            color: readOnlyFeed
              ? theme.palette.primary.main
              : theme.palette.neutral.main,
          }}
        >
          Listen-only mode
        </Typography>
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{ color: theme.palette.neutral.main, fontSize: "0.875rem" }}
          >
            Only you will be able to post. You can not modify this setting
            later.
          </Typography>
          <Box sx={{ width: "120px", justifyContent: "end", display: "flex" }}>
            <Checkbox checked={readOnlyFeed} onChange={toggleReadOnly} />
          </Box>
        </Stack>
      </Box>
    );
  };

  if (
    workspaceMemberships?.length === 0 &&
    allowNewWorkspaceCreation === false
  ) {
    return <NoValidWorkspacesModal open={true} />;
  }

  return (
    <ModalForm
      id="onboarding"
      allowClose={false}
      disableClose={true}
      open={open}
      onClose={null}
      closeAfterTransition={true}
      disableEscapeKeyDown={true}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: theme.palette.primary.dark,
            backgroundImage:
              "url(https://storyboard-fm.cdn.prismic.io/storyboard-fm/d3c6f3e4-ffa5-4bb2-9564-5378b56356a0_Login+Background.svg)",
            backgroundPosition: "50%",
            backgroundSize: "cover",
          },
        },
      }}
      sx={{
        border: "none",
        boxShadow: "none",
      }}
      maxHeight={currentActiveState === 4 ? 750 : 600}
    >
      <>
        {showSteps && (
          <Box
            sx={{
              padding: 2,
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
            }}
          >
            <Stepper steps={status} currentActiveStep={currentActiveState} />
          </Box>
        )}
        <Box className="full-button-width" sx={{ width: "100%", mt: 4 }}>
          {currentActiveState === 1 && (
            <Box sx={{ textAlign: "center" }}>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                <AvatarWithStatus size="lg" accountId={myAccount?.id} />
              </Box>
              <Typography
                variant="h5"
                component="h2"
                sx={{ color: theme.palette.primary.main, mb: 1 }}
                fontWeight={700}
                aria-label={Locator.onBoarding.modalTitle}
              >
                {Locator.onBoarding.modalTitle}
              </Typography>

              <FullInput
                aria-label={Locator.onBoarding.fullNameInput}
                fullWidth
                error={error}
                helperText="Required *"
                required
                label={Locator.onBoarding.fullNameInput}
                value={fullName}
                callback={(e) => setFullName(e.target.value)}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                aria-label={Locator.onBoarding.submitButton}
                size="large"
                loading={submitting}
                onClick={saveFullName}
                disabled={!isFullNameValid || submitting}
                sx={{
                  width: "100%",
                }}
              >
                {Locator.onBoarding.submitButton}
              </LoadingButton>
            </Box>
          )}
          {currentActiveState === 2 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                gap: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
                Workspace
              </Typography>
              <Typography>
                A place for your team to have discussions, receive updates via
                audio, and share information internally.
              </Typography>
              <FullInput
                id="workspace-name"
                label="Workspace name"
                placeholder="E.g. Company name"
                disabled={submitting}
                value={workspaceName}
                callback={(e) => setWorkspaceName(e.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 2.5,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={prevStep}
                  disabled={submitting}
                  aria-label={"Cancel workspace creation"}
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    order: { xs: 1, sm: 0 },
                    flexGrow: 1,
                    flexBasis: "100%",
                  }}
                >
                  CANCEL
                </Button>
                <LoadingButton
                  disabled={!workspaceName || submitting}
                  loading={submitting}
                  variant="contained"
                  color="primary"
                  onClick={handleWorkspaceCreation}
                  aria-label="Continue"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    order: { xs: 0, sm: 1 },
                    flexGrow: 1,
                    flexBasis: "100%",
                  }}
                >
                  Continue
                </LoadingButton>
              </Box>
            </Box>
          )}
          {currentActiveState === 3 && (
            <>
              {invitesSkipped ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    fontWeight: 500,
                    gap: 3,
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h3"
                    sx={{ fontWeight: 700 }}
                  >
                    Skip inviting?
                  </Typography>
                  <Typography>
                    Chat by Storyboard enables team collaboration. To experience
                    the full set of features, you will need to invite your
                    colleagues.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 2.5,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleInviteSkipCancel}
                      aria-label={"Go back to invites"}
                      sx={{
                        width: { xs: "100%", sm: "auto" },
                        order: { xs: 1, sm: 0 },
                        flexGrow: 1,
                        flexBasis: "100%",
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleInviteSkipConfirm}
                      aria-label="Skip inviting for now"
                      sx={{
                        width: { xs: "100%", sm: "auto" },
                        order: { xs: 0, sm: 1 },
                        flexGrow: 1,
                        flexBasis: "100%",
                      }}
                    >
                      Skip step
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    fontWeight: 500,
                    gap: 1.5,
                    pt: { xs: 2, sm: 0 },
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h3"
                    sx={{ fontWeight: 700 }}
                  >
                    Invite new users to workspace
                  </Typography>
                  <Typography>
                    Enter an email address or phone number to invite new users.
                  </Typography>
                  <StyledChipsInput
                    inputRef={handleContactHelperText}
                    placeholder="Type or paste emails or phone numbers..."
                    aria-placeholder="Type or paste emails or phone numbers..."
                    aria-label={
                      Locator.workspaceNav.members.inviteModal.inviteTextField
                    }
                    helperText={contactHelperText || ""}
                    value={contacts}
                    addOnWhichKey={["Enter", "Tab", ";", ","]}
                    validate={validateContact}
                    onChange={handleContactsChange}
                    renderChip={(Component, key, props) => {
                      return (
                        <Component
                          {...props}
                          key={key}
                          deleteIcon={<Close />}
                        />
                      );
                    }}
                    hideClearAll
                    addOnBlur={true}
                  />
                  {error && (
                    <Box
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{ color: theme.palette.error.contrastText }}
                      >
                        {errorText}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      gap: 2.5,
                    }}
                  >
                    <LoadingButton
                      loading={submitting}
                      variant="contained"
                      color="primary"
                      onClick={handleInviteClick}
                      sx={{
                        width: "100%",
                      }}
                      disabled={invitesCount === 0}
                    >
                      Continue
                    </LoadingButton>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleInviteSkip}
                      aria-label="Skip inviting for now"
                      sx={{
                        width: "auto",
                        textTransform: "none",
                        color: theme.palette.neutral.main,
                        fontWeight: 500,
                      }}
                    >
                      Skip for now
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
          {currentActiveState === 4 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                fontWeight: 500,
                gap: 1.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  fontWeight: 500,
                  gap: 1.5,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ fontWeight: 700 }}
                >
                  Create a new channel
                </Typography>
                <Typography>
                  Channels are conversations with one or more users in your
                  Workspace.
                </Typography>
              </Box>

              <FullInput
                id="new-channelname"
                label={<ChannelNameLabel />}
                placeholder="E.g. Weekly updates, Carol/Peter,"
                disabled={submitting}
                value={newChannelName}
                callback={(e) => setNewChannelName(e.target.value)}
                error={newChannelName?.length > channelNameCharLimit}
                formControlProps={{
                  sx: {
                    "& .MuiFormHelperText-root": {
                      mb: 0,
                    },
                  },
                }}
                helperText={
                  <ChannelNameHelperText
                    characterLimit={channelNameCharLimit}
                    field={newChannelName}
                  />
                }
              />

              <Box sx={{ boxModel: "border-box" }}>
                <Typography sx={{ mb: 1, fontWeight: 500 }}>
                  Select a channel type:
                </Typography>
                <Box
                  sx={{
                    background: theme.palette.secondary.main,
                    borderRadius: 2,
                    p: 2,
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: isPrivate
                        ? theme.palette.primary.main
                        : theme.palette.neutral.main,
                    }}
                  >
                    Private Channel
                  </Typography>
                  <Stack
                    sx={{
                      display: "flex",
                      mb: 2,
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.neutral.main,
                        fontSize: "0.875rem",
                      }}
                    >
                      Any number of workspace users can join this channel by
                      owner invitation.
                    </Typography>
                    <Box
                      sx={{
                        width: "120px",
                        justifyContent: "end",
                        display: "flex",
                      }}
                    >
                      <Radio
                        checked={isPrivate}
                        onClick={() => changeChannelType(true)}
                      />
                    </Box>
                  </Stack>
                  {isPrivate ? <ListenOnlyMode /> : null}
                </Box>
                <Box sx={{ px: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: !isPrivate
                        ? theme.palette.primary.main
                        : theme.palette.neutral.main,
                    }}
                  >
                    Public Channel
                  </Typography>
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.neutral.main,
                        fontSize: "0.875rem",
                      }}
                    >
                      All workspace members can find and join this channel.
                    </Typography>
                    <Box
                      sx={{
                        width: "120px",
                        justifyContent: "end",
                        display: "flex",
                      }}
                    >
                      <Radio
                        checked={!isPrivate}
                        onClick={() => changeChannelType(false)}
                      />
                    </Box>
                  </Stack>
                  {!isPrivate ? <ListenOnlyMode /> : null}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 2.5,
                }}
              >
                <LoadingButton
                  loading={submitting}
                  disabled={newChannelName?.length > channelNameCharLimit}
                  variant="contained"
                  color="primary"
                  onClick={handleCreateWorkspaceFeed}
                  aria-label="Create workspace channel"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    order: { xs: 0, sm: 1 },
                    flexGrow: 1,
                    flexBasis: "100%",
                  }}
                >
                  Continue
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      </>
    </ModalForm>
  );
}
