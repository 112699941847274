import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@mui/material";
import Locator from "@/locator";
import { DataContext } from "@/models/DataProvider";
import { useLocalStorage } from "@uidotdev/usehooks";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";

interface FilterProps {
  changeChannelType: (value: string) => void;
  changeChannelSortOrder: (value: string) => void;
}

export default function SearchFilter({
  changeChannelType,
  changeChannelSortOrder,
}: FilterProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const { isWorkspaceLimitedMember } = useContext(WorkspaceContext);
  const [savedChannelOrder] = useLocalStorage("channel-sort-order");
  const [savedChannelType] = useLocalStorage("channel-sort-type");

  const limitedMember = isWorkspaceLimitedMember();

  interface FilterType {
    key: string;
    text: string;
    default: boolean;
  }

  const filters = [
    {
      key: "ShowChannels",
      text: "Show Channels",
      children: [
        ...(!limitedMember
          ? [
            {
              key: "all",
              text: "All",
              default: false,
              label: Locator.workspaceNav.channels.filter.showAll,
            },
          ]
          : []),
        {
          key: "unread",
          text: "Unread messages",
          default: false,
          label: Locator.workspaceNav.channels.filter.showUnread,
        },
        {
          key: "my-channels",
          text: "My Channels",
          default: true,
          label: Locator.workspaceNav.channels.filter.showMy,
        },
      ],
    },
    {
      key: "SortChannels",
      text: "Sort Channels",
      children: [
        {
          key: "recent",
          text: "By recent driver messages",
          default: true,
          label: Locator.workspaceNav.channels.filter.sortRecent,
        },
        {
          key: "alpha",
          text: "Alphabetically",
          default: false,
          label: Locator.workspaceNav.channels.filter.sortAlpha,
        },
      ],
    },
  ];

  const [activeType, setActiveType] = useState<FilterType>(
    filters[0].children.find((type) => type.key === savedChannelType) ||
    filters[0].children.find((type) => type.default),
  );
  const [activeOrder, setActiveOrder] = useState<FilterType>(
    filters[1].children.find((type) => type.key === savedChannelOrder) ||
    filters[1].children.find((type) => type.default),
  );

  const hasNonDefaultValue =
    activeType?.default === false || activeOrder?.default === false;

  const setChannelTypeSort = (filterKey: string, child: FilterType) => {
    if (filterKey === "ShowChannels") {
      setActiveType(() => child);
      changeChannelType(child.key);
    } else if (filterKey === "SortChannels") {
      setActiveOrder(() => child);
      changeChannelSortOrder(child.key);
    }
  };

  const activeIcon = (filterKey: string, childKey: string) => {
    if (filterKey === "ShowChannels") {
      return childKey === activeType?.key;
    } else if (filterKey === "SortChannels") {
      return childKey === activeOrder?.key;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Filter Options">
          <IconButton
            onClick={handleClick}
            aria-label={Locator.workspaceNav.channels.filter.options}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{
              borderRadius: 4,
              border: hasNonDefaultValue
                ? `solid 2px ${theme.palette.brand.secondary.light} !important`
                : "solid 2px transparent !important",
              "&:focus:not([disabled])": {
                background: `${theme.palette.secondary.dark} !important`,
              },
              padding: 1.25,
            }}
          >
            <FilterListIcon
              sx={{
                color: hasNonDefaultValue
                  ? theme.palette.brand.secondary.light
                  : "white",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {filters?.map((filter, index) => (
          <Box
            key={filter.key}
            sx={{
              borderBottom:
                index !== filters.length - 1 &&
                `solid 1px ${theme.palette.secondary.light}`,
              pb: index !== filters.length - 1 && 1,
              mb: index !== filters.length - 1 && 1,
            }}
          >
            <Box sx={{ fontSize: "0.85rem", px: 1, mb: 1 }}>{filter.text}</Box>
            <Box>
              {filter.children.map((child) => (
                <MenuItem
                  key={child.key}
                  onClick={() => setChannelTypeSort(filter.key, child)}
                  aria-label={child.label}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      mr: 1,
                      color: theme.palette.brand.secondary.light,
                    }}
                  >
                    {activeIcon(filter.key, child.key) && (
                      <CheckIcon sx={{ fontSize: "18px" }} />
                    )}
                  </Box>
                  <Box>{child.text}</Box>
                </MenuItem>
              ))}
            </Box>
          </Box>
        ))}
      </Menu>
    </>
  );
}
