import { useElectric } from "@/electric/ElectricWrapper";
import { Account, Workspace, WorkspaceMembership } from "@/generated/client";
import { useLiveQuery } from "electric-sql/react";
import React, { useContext, useEffect } from "react";
import { createContext } from "react";
import { MyAccountContext } from "./myAccountProvider";
import { useParams } from "react-router-dom";
import { upsertDirectWsInvitation, upsertWorkspaceMembership, upsertWsAccount } from "@/data/workspace";
import App from "@/App";
import { AppContext } from "../AppStateProvider";

export type WorkspaceState = {
  currentWorkspaceId?: string;
  myCurrentWorkspaceMembership?: WorkspaceMembership;
  workspaceMemberships?: WorkspaceMembership[];
  isWorkspaceAdmin?: () => boolean;
  isWorkspaceLimitedMember?: () => boolean;
  workspaces?: Workspace[];
}

export const WorkspaceContext = createContext<WorkspaceState>({});


const WorkspaceProvider = ({ children }) => {
  const { db } = useElectric();
  const { client } = useContext(AppContext);
  const { myAccountId } = React.useContext(MyAccountContext);
  const { workspaceId } = useParams();

  const { results: myCurrentWorkspaceMembership } = useLiveQuery(() => {
    if (!myAccountId || !workspaceId) return;
    return db.workspace_membership.liveFirst({
      where: {
        workspaceId,
        accountId: myAccountId,
        status: "active",
      },
    });
  }, [myAccountId, workspaceId]);

  const { results: workspaceMemberships } = useLiveQuery(() => {
    if (!workspaceId) return;
    return db.workspace_membership.liveMany({
      where: {
        status: "active",
        workspaceId,
      },
    });
  }, [workspaceId]);

  // TODO - limit these to my active memberships
  const { results: workspaces } = useLiveQuery(db.workspace.liveMany({
    orderBy: {
      name: "asc",
    },
  }));

  const isWorkspaceAdmin = React.useCallback(() => {
    return myCurrentWorkspaceMembership?.role === "admin";
  }, [myCurrentWorkspaceMembership]);

  const isWorkspaceLimitedMember = React.useCallback(() => {
    return myCurrentWorkspaceMembership?.role === "limitedMember";
  }, [myCurrentWorkspaceMembership]);


  useEffect(() => {
    const f = async () => {
      try {
        const membership = await db.workspace_membership.findFirst({
          where: {
            accountId: myAccountId,
            workspaceId,
            status: "active",
          },
        });
        if (
          !myAccountId ||
          !workspaceId ||
          membership?.role === "limitedMember"
        )
          return;
        await client.getWorkspaceInvitations(workspaceId).then((resp) => {
          for (const membership of resp?.workspaceMemberships || []) {
            upsertWorkspaceMembership(db, membership);
          }
          for (const account of resp?.accounts || []) {
            upsertWsAccount(db, account);
          }
          for (const directInvitation of resp?.directInvitations || []) {
            upsertDirectWsInvitation(db, directInvitation);
          }
        });
      } catch (e) {
        console.error("Error loading workspace details", e);
      }
    };
    f();
  },
    [myAccountId, workspaceId],
  );

  return (
    <WorkspaceContext.Provider value={{
      currentWorkspaceId: workspaceId,
      myCurrentWorkspaceMembership,
      workspaceMemberships,
      workspaces,

      isWorkspaceAdmin,
      isWorkspaceLimitedMember,
    }}>
      {children}
    </WorkspaceContext.Provider>
  );
}

export default WorkspaceProvider;