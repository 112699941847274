import UseContextMenu from "@/components/ContextMenus/UseContextMenu";
import { WorkspaceFeedGroup } from "@/components/Workspace/WorkspaceChannelList";
import { useElectric } from "@/electric/ElectricWrapper";
import Locator from "@/locator";
import { DataContext } from "@/models/DataProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import type { SxProps } from "@mui/system";
import { useLiveQuery } from "electric-sql/react";
import type * as React from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ChannelGroupContextMenu({
  children,
  sx,
  group,
  membershipId,
  markAllChannelsAsRead,
  joiningHandler,
  setParentDisabled,
}: {
  children: React.JSX.Element;
  sx?: SxProps;
  group: WorkspaceFeedGroup;
  membershipId: string;
  markAllChannelsAsRead: () => void;
  joiningHandler?: Function;
  setParentDisabled?: (value: boolean) => void;
}) {
  const { fetchNextActiveChannel } =
    useContext(LiveQueryContext);
  const { userReadOnlyMode } = useContext(UxContext);
  const navigate = useNavigate();
  const { db } = useElectric();
  const {
    joinFeedGroup,
    leaveFeedGroup,
  } = useContext(DataContext);

  const { myCurrentWorkspaceMembership, isWorkspaceLimitedMember, currentWorkspaceId } = useContext(WorkspaceContext);
  const { ampli } = useContext(TrackingContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { handleContextMenu, handleClose, defaultStyles, contextMenuItem } =
    UseContextMenu();
  if (!group?.id) {
    return;
  }
  const { joined } = group;
  const limitedMember = isWorkspaceLimitedMember();

  const { results: allGroupFeeds } = useLiveQuery(() => {
    if (!currentWorkspaceId || !group || !myCurrentWorkspaceMembership) {
      return;
    }
    return db.liveRaw({
      sql: `
        SELECT 
          feed_group_membership.id,
          feed_group_membership.feedId,
          feed_group_membership.groupId,
          ( feed_group_membership.feedId IN ( SELECT permission.feedId FROM permission WHERE permission.workspace_membershipId = '${myCurrentWorkspaceMembership?.id}' AND permission.name = 'read' AND enabled = true)) as joined
        FROM 
          feed_group_membership
        JOIN
          feed ON feed.id = feed_group_membership.feedId AND feed_group_membership.groupId = ?
        WHERE 
          feed.workspaceId = '${currentWorkspaceId}' AND isDm = 0 AND feed.isPrivate = 0 AND feed.id NOT IN ( SELECT permission.feedId FROM permission WHERE permission.workspace_membershipId = '${myCurrentWorkspaceMembership?.id}' AND permission.name = 'read' AND enabled = true) 
        OR 
          feed.workspaceId = '${currentWorkspaceId}' AND isDm = 0 AND feed.id IN ( SELECT permission.feedId FROM permission WHERE permission.workspace_membershipId = '${myCurrentWorkspaceMembership?.id}' AND permission.name = 'read' AND enabled = true)
        `,
      args: [group?.id],
    });
  }, [currentWorkspaceId, group.id, myCurrentWorkspaceMembership.id]);

  const allChannelsJoined = allGroupFeeds?.every((f) => f.joined);

  const joinOrLeaveGroup = async () => {
    setDisabled(() => true);
    setParentDisabled(true);
    if (group?.joined) {
      ampli.groupLeave({ workspaceId: currentWorkspaceId });
      handleClose();

      await leaveFeedGroup({
        workspaceId: currentWorkspaceId,
        feedGroupId: group.id,
      });

      setParentDisabled(false);
      const redirectUrl = await fetchNextActiveChannel(group.id);

      navigate(redirectUrl);
      setDisabled(() => false);
    } else {
      handleClose();
      handleJoinAllChannels();
    }

    setDisabled(() => false);
  };

  const handleJoinAllChannels = async () => {
    setDisabled(() => true);
    try {
      ampli.groupJoin({ workspaceId: currentWorkspaceId });
      joiningHandler && joiningHandler(true);
      await joinFeedGroup({
        workspaceId: currentWorkspaceId,
        feedGroupId: group.id,
      });
      joiningHandler && joiningHandler(false);
    } catch (e) {
      console.error(e);
    }
    setDisabled(() => false);
  };

  const handleMarkAllChannelsAsRead = async () => {
    setDisabled(() => true);
    ampli.groupMarkAllRead({ workspaceId: currentWorkspaceId });
    markAllChannelsAsRead();
    setDisabled(() => false);
    handleClose();
  };

  const ariaLabelJoin = joined
    ? Locator.workspaceNav.channels.list.groups.leave
    : Locator.workspaceNav.channels.list.groups.join;

  return (
    <Box
      sx={defaultStyles(sx)}
      onContextMenu={handleContextMenu}
      style={{ cursor: "context-menu" }}
    >
      {children}

      {contextMenuItem([
        !userReadOnlyMode ? (
          <MenuItem
            aria-label={Locator.workspaceNav.channels.list.markAllAsRead}
            key={Locator.workspaceNav.channels.list.markAllAsRead}
            disabled={disabled}
            onClick={handleMarkAllChannelsAsRead}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                mr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <DraftsOutlinedIcon />
            </Box>
            <Box>Mark all as Read</Box>
          </MenuItem>
        ) : null,
        !limitedMember && joined && !allChannelsJoined ? (
          <MenuItem
            aria-label={Locator.workspaceNav.channels.list.groups.joinAll}
            key={Locator.workspaceNav.channels.list.groups.joinAll}
            disabled={disabled}
            onClick={() => {
              handleClose();
              handleJoinAllChannels();
            }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                mr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <LoginIcon />
            </Box>
            <Box>Join all channels</Box>
          </MenuItem>
        ) : null,
        !limitedMember ? (
          <MenuItem
            aria-label={ariaLabelJoin}
            key={ariaLabelJoin}
            disabled={disabled}
            onClick={() => joinOrLeaveGroup()}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                mr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {group?.joined ? <LogoutIcon /> : <LoginIcon />}
            </Box>
            <Box>{group?.joined ? "Leave" : "Join"} Group</Box>
          </MenuItem>
        ) : null,
      ])}
    </Box>
  );
}
