import ContextualFooter from "@/components/ContextualFooter";
import Loading from "@/components/Loading/Index";
import SessionRecorderFooter from "@/components/SessionRecorderFooter/Index";
import { useElectric } from "@/electric/ElectricWrapper";
import Locator from "@/locator";
import { AppContext } from "@/models/AppStateProvider";
import { FeedContext } from "@/models/FeedContextProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import { downloadFeedItemContent, downloadPaginatedFeedItems } from "@/models/actions/initialFeedLoad";
import { VolumeOffOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Fade,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import {
  Fragment,
  startTransition,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "regenerator-runtime/runtime";
import { VList } from "virtua";
import FeedItem from "./FeedItem";
import FeedItemHeader from "./FeedItemHeader";
import { FeedMap } from "./FeedMap";
import { drawerOpenStyles } from "./SBAppBar";
import AddChannelUsersLink from "./Workspace/AddChannelUsersLink";

export default function FeedC() {
  const {
    status: feedStatus,
    itemTranscriptState,
    itemMessageDetailsState,
    vListRef,
  } = useContext(FeedContext);
  const navigate = useNavigate();
  const { isSmUp, leftNavOpen, rightNavOpen, userReadOnlyMode } =
    useContext(UxContext);
  // const [showToastPlayer, setShowToastPlayer] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [activeFeedItemId, setActiveFeedItemId] = useState<string | null>(null);
  const [scrolling, setScrolling] = useState(false);
  const [range, setRange] = useState([-1, -1]);
  const { client, flags } = useContext(AppContext);

  const { currentFeed, haveWritePermission, isCurrentFeedAdmin } = useContext(CurrentFeedContext);
  const { currentWorkspaceId } = useContext(WorkspaceContext);
  const { myAccountId } = useContext(MyAccountContext)
  const { db } = useElectric();

  const { hash } = useLocation();
  const wls = window?.location?.search;
  const feedItemId = new URLSearchParams(wls).get("feedItemId") ?? null;
  const isSilentChannel = currentFeed?.isSilent === 1;
  const feedId = currentFeed?.id;



  /**
   * If the current user is removed from a workspace, redirect them to the workspaces page
   * Or if they are in a workspace that they are not a member of, redirect them to the workspaces page
   */
  // useEffect(() => {
  //   const foundWorkspaceMembership = workspaceMemberships?.find(
  //     (wm) => wm.workspaceId === currentWorkspaceId,
  //   );
  //   if (!foundWorkspaceMembership) {
  //     navigate("/workspaces");
  //   }
  // }, [workspaceMemberships, currentWorkspaceId]);

  const { results: items } = useLiveQuery(() => {
    if (!feedId) return;
    const r = db.item.liveMany({
      where: {
        feedId: feedId,
        deletedAt: null,
      },
      orderBy: {
        createdAt: "asc",
      },
    });
    return r;
  }, [feedId]);


  let currentFeedItems = FeedMap({ items, feedId });


  const delayAddUserWindow = useRef<boolean>(true);
  useEffect(() => {
    setTimeout(() => {
      delayAddUserWindow.current = false;
    }, 2000);
  }, []);

  const dateGroups = useMemo(
    () =>
      currentFeedItems?.reduce((acc, item, index) => {
        if (!acc[item?.shortDateStr]) {
          acc[item?.shortDateStr] = {
            firstItemIndex: index,
            lastItemIndex: index,
          };
        } else {
          acc[item?.shortDateStr].lastItemIndex = index;
        }
        return acc;
      }, {}),
    [currentFeedItems],
  );

  const unreadItems = useMemo(
    () =>
      items
        ?.filter((i) => i.unread)
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)),
    [items],
  );

  const canRecordOrUpload = haveWritePermission;
  const theme = useTheme();
  const unreadCount = unreadItems?.length;
  const firstUnseen =
    myAccountId && unreadCount > 0 ? unreadItems[0]?.id : null;
  const unreadIndex = useMemo(
    () =>
      currentFeedItems?.find(
        (feedItem) => unreadItems?.[0]?.id === feedItem?.id,
      )?.feedItemIndex,
    [currentFeedItems, unreadItems],
  );

  const keepMountedItems = useMemo(
    () =>
      currentFeedItems?.reduce(
        (acc, item, index) =>
          acc.concat(
            itemTranscriptState[item?.id] ||
              itemMessageDetailsState[item?.id] ||
              firstUnseen === item?.id
              ? index
              : [],
          ),
        [],
      ),
    [
      currentFeedItems,
      itemTranscriptState,
      itemMessageDetailsState,
      firstUnseen,
    ],
  );

  const [activeStickyItem, setActiveStickyItem] = useState(null);

  const initialLoad = useRef(true);
  const shouldStickToBottom = useRef(false);
  const loadingContent = useRef(false);
  const lastItemSpacing = useMemo(() => {
    const spacing = isSilentChannel ? 245 : 220;
    if (isSmUp) {
      return `${spacing}px`;
    }
    return `${spacing + 80}px`;
  }, [isSmUp, isSilentChannel]);

  // Formally used for paginated data infinite loading
  const rangeChangeLoadMore = async (
    vListIndexStart: number,
    vListIndexEnd: number,
  ) => {
    if (items.length === 0) return;
    if (loadingContent.current) return;
    // console.log(
    // 	"Range change",
    // 	vListIndexStart,
    // 	vListIndexEnd,
    // 	currentFeedItems.length,
    // );
    const allItemsNotLoadedUpWithin10 = items?.filter((item, index) => {
      return index > vListIndexStart - 10 && index < vListIndexEnd + 10;
    });
    if (allItemsNotLoadedUpWithin10.length === 0) {
      return;
    }
    loadingContent.current = true;
    const itemContentIds = allItemsNotLoadedUpWithin10
      .filter((item) => item.loadedContent === 0)
      ?.map((i) => i.id);
    downloadFeedItemContent(client, db, currentWorkspaceId, itemContentIds);
    // downloadOldestFeedItemEvents(client, db, itemIds, workspaceId);
    loadingContent.current = false;
  };

  const handleRangeChange = async (startIndex: number, endIndex: number) => {
    const dateGroup = dateGroups[currentFeedItems?.[startIndex]?.shortDateStr];
    if (
      !initialLoad.current &&
      startIndex >= dateGroup?.firstItemIndex &&
      startIndex <= dateGroup?.lastItemIndex
    ) {
      if (
        (unreadCount > 0 &&
          startIndex >= unreadIndex &&
          unreadIndex <= endIndex) ||
        (startIndex < unreadIndex && unreadIndex > endIndex)
      ) {
        setActiveStickyItem({
          ...currentFeedItems[unreadIndex],
          index: unreadIndex,
          isUnreadAbove: unreadIndex < startIndex,
          showArrow: unreadIndex !== startIndex,
        });
      } else {
        setActiveStickyItem(null);
      }
    }
    await rangeChangeLoadMore(startIndex, endIndex);
  };

  const scrollToActiveId = (index: number) => {
    vListRef.current?.scrollToIndex(index, { align: "start" });
  };

  // const dismissToast = () => {
  //   if (playerPlaying) {
  //     pauseItem();
  //   }
  //   setShowToastPlayer(false);
  // };

  useEffect(() => {
    initialLoad.current = true;
    currentFeedItems = [];
  }, [feedId]);

  const checkLoadedState = useRef(false);

  useEffect(() => {
    if (currentFeedItems?.length === 0 && !checkLoadedState.current) {
      downloadPaginatedFeedItems(client, db, currentWorkspaceId, feedId, 1, 0);
      checkLoadedState.current = true;
    }
  }, [currentWorkspaceId, feedId, currentFeedItems]);

  useEffect(() => {
    // console.log("Checking active feed item", feedId);
    if (feedItemId && currentFeedItems?.length > 0 && !activeFeedItemId) {
      const findVlistIndex = currentFeedItems?.findIndex(
        (item) => item.id === feedItemId,
      );
      if (findVlistIndex > -1) {
        setTimeout(() => {
          vListRef?.current?.scrollToIndex(findVlistIndex, {
            align: "center",
            offset: 200,
          });
          setActiveFeedItemId((prev) =>
            prev !== feedItemId ? feedItemId : prev,
          );
          setTimeout(() => {
            setActiveFeedItemId((prev) => (prev !== null ? null : prev));
            // (dsq) = Don't stop queue on url change
            navigate(`/workspaces/${currentWorkspaceId}/feeds/${feedId}?dsq=true`);
          }, 5000);
        }, 250);
      }
    }
  }, [feedItemId, currentFeedItems, vListRef, feedId, currentWorkspaceId]);

  useEffect(() => {
    if (!vListRef?.current) return;
    if (
      initialLoad.current &&
      checkLoadedState.current &&
      currentFeedItems.length > 0
    ) {
      let scrollToIndex = currentFeedItems.length - 1;
      if (hash) {
        const hashId = hash?.split("#")?.[1];
        scrollToIndex = currentFeedItems?.findIndex(
          (item) => item.id === hashId,
        );
      }
      vListRef?.current?.scrollToIndex(scrollToIndex, {
        align: hash ? "center" : "end",
      });
      initialLoad.current = false;
    }
  }, [currentFeedItems, hash, vListRef]);

  useEffect(() => {
    if (!vListRef?.current) return;
    // if were past the initial load
    if (!initialLoad.current && checkLoadedState.current) {
      const scrollToIndex = currentFeedItems?.length - 1;
      // if at bottom of list already, then scroll to bottom, otherwise keep scroll position
      if (shouldStickToBottom.current) {
        vListRef?.current?.scrollToIndex(scrollToIndex, {
          align: "end",
        });
      }
    }
  }, [currentFeedItems, vListRef]);

  const handleVListScroll = (offset?: number) => {
    setScrolling(true);
    // if were at the bottom of the list, then stick to the bottom, otherwise maintain scroll position
    shouldStickToBottom.current =
      offset - vListRef.current.scrollSize + vListRef.current.viewportSize >= 0;
  };

  const _drawerOpenStyles = {
    ...drawerOpenStyles({
      isSmUp,
      leftNavOpen,
      rightNavOpen,
      theme,
    }),
  };

  if (!feedId || !currentFeedItems) return null;

  return (
    <>
      {feedStatus && feedStatus?.message ? (
        <Fade in={feedStatus?.message !== null} timeout={500}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{
              width: "100%",
              padding: 1,
              top: { xs: 100 },
              left: { xs: 0 },
              transform: { xs: "none" },
              zIndex: 1199,
              ..._drawerOpenStyles,
            }}
            open={true}
          >
            <Alert
              severity={feedStatus?.severity}
              sx={{
                width: "100%",
                color: theme.palette.primary.main,
                maxWidth: 425,
              }}
              icon={false}
              elevation={6}
              variant="filled"
            >
              {feedStatus?.message}
            </Alert>
          </Snackbar>
        </Fade>
      ) : null}
      {/* {showToastPlayer ? (
        <Box
          key={"toast-player"}
          sx={{
            width: "100%",
            position: "fixed",
            top: 164,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: 2,
            zIndex: 1100,
            ..._drawerOpenStyles,
          }}
        >
          <ToastPlayer onClose={dismissToast} />
        </Box>
      ) : null} */}
      {fetching ? (
        <Box
          sx={{
            p: 2,
            height: 80,
            display: "flex",
            alignItems: "center",
            position: "fixed",
            justifyContent: "center",
            left: 0,
            top: "120px",
            width: "100%",
            zIndex: 200,
            ..._drawerOpenStyles,
          }}
        >
          <Loading size="small" />
        </Box>
      ) : null}
      <Stack
        sx={{
          position: "fixed",
          top: 108,
          left: 0,
          width: "100%",
          zIndex: 1100,
          ..._drawerOpenStyles,
        }}
      >
        {activeStickyItem ? (
          <FeedItemHeader
            isStuck={true}
            dateId={activeStickyItem?.shortDateStr}
            onClick={() => scrollToActiveId(activeStickyItem?.index)}
            isUnreadHeader={firstUnseen && firstUnseen === activeStickyItem?.id}
            unreadCount={unreadCount}
            unreadAbove={activeStickyItem?.isUnreadAbove}
            showArrow={activeStickyItem?.showArrow}
          />
        ) : null}
      </Stack>
      {currentFeedItems?.length > 0 ? (
        <VList
          className="scrollable-content"
          aria-label={Locator.feed.items.list}
          data-testid={Locator.feed.testId(feedId)}
          style={{
            flex: 1,
            height: "calc(100svh - 92px)",
            paddingRight: "6px",
            paddingLeft: "16px",
          }}
          reverse
          shift={false}
          // setting to "0" removes the bottom jump issue
          overscan={0}
          ref={vListRef}
          id="vListRef"
          keepMounted={keepMountedItems}
          onRangeChange={(startIndex, endIndex) => {
            startTransition(() => {
              setRange([startIndex, endIndex]);
            });
            requestAnimationFrame(() =>
              handleRangeChange(startIndex, endIndex),
            );
          }}
          onScroll={handleVListScroll}
          onScrollEnd={() => {
            startTransition(() => {
              setScrolling(false);
            });
          }}
          itemSize={172}
        >
          {currentFeedItems.map((feedItem, index) => (
            <Fragment key={`feed-item-${feedItem?.id}`}>
              {feedItem.isFirstDateGroupItem ||
                (firstUnseen && firstUnseen === feedItem?.id) ? (
                <FeedItemHeader
                  isStuck={false}
                  dateId={feedItem.shortDateStr}
                  onClick={() => scrollToActiveId(index)}
                  dateLabel={feedItem.dateGroupLabel}
                  isUnreadHeader={firstUnseen && firstUnseen === feedItem?.id}
                  unreadCount={unreadCount}
                />
              ) : (
                <Box sx={{ height: 34 }}></Box>
              )}
              <Box
                id={`item-${index}`}
                aria-label={Locator.feed.items.item}
                data-testid={feedItem.id}
                sx={{
                  maxWidth: { xs: 1300 },
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  pb:
                    index === currentFeedItems?.length - 1
                      ? lastItemSpacing
                      : 0,
                  justifyContent: feedItem.mine ? "flex-end" : "flex-start",
                }}
              >
                <FeedItem
                  feed={currentFeed}
                  item={feedItem}
                  vListIndex={index}
                  active={feedItem.id === activeFeedItemId}
                  highlightPodcast={feedItem.id === activeFeedItemId}
                />
              </Box>
            </Fragment>
          ))}
        </VList>
      ) : (
        <Box
          aria-label={Locator.feed.items.list}
          data-testid={Locator.feed.testId(feedId)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height:
              currentFeedItems.length === 0 ? "calc(100svh - 165px)" : "auto",
            color: theme.palette.secondary.light,
          }}
        >
          <>
            {currentFeedItems.length === 0 ? (
              <>
                {currentFeed.isDm === 1 ? (
                  <Typography
                    variant="h6"
                    component="h4"
                    color={theme.palette.primary.main}
                    fontWeight={700}
                  >
                    There are no messages yet
                  </Typography>
                ) : null}
                {(currentFeed.isDm !== 1 &&
                  isCurrentFeedAdmin &&
                  currentFeed?.isPrivate === 1 &&
                  !delayAddUserWindow.current) ||
                  (!currentFeed.isDm &&
                    currentFeed?.isPrivate === 0 &&
                    !delayAddUserWindow.current) ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      gap: "28px",
                      width: "100%",
                      maxWidth: { xs: "calc(100% - 32px)", sm: "415px" },
                      padding: "40px 60px",
                      borderRadius: "8px",
                      background: theme.palette.secondary.dark,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        component="h4"
                        color={theme.palette.primary.main}
                        fontWeight={700}
                      >
                        Add Users to this Channel
                      </Typography>
                      <Typography color={theme.palette.neutral.main}>
                        Invite existing workspace users to start a conversation
                      </Typography>
                    </Box>
                    <AddChannelUsersLink />
                  </Box>
                ) : null}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 0.5,
                  py: 4,
                  color: theme.palette.neutral.main,
                }}
              >
                <Typography fontWeight={700}>
                  There is no one else here....
                </Typography>
                <AddChannelUsersLink
                  variant="link"
                  sx={{ color: theme.palette.neutral.main }}
                />
              </Box>
            )}
          </>
        </Box>
      )}
      {isSilentChannel && canRecordOrUpload ? (
        <ContextualFooter
          containerSx={{
            bottom: canRecordOrUpload ? 204 : 52,
            height: 34,
          }}
          sx={{
            py: 0.5,
            px: 3,
            maxWidth: 180,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <VolumeOffOutlined role="presentation" sx={{ pr: 0.5 }} />
            <Typography
              sx={{ fontSize: "12px", fontStyle: "italic", fontWeight: 500 }}
            >
              Hands-free is off
            </Typography>
          </Box>
        </ContextualFooter>
      ) : null}
      {canRecordOrUpload && !userReadOnlyMode ? (
        <>
          <SessionRecorderFooter workspaceId={currentWorkspaceId} feedId={feedId} />
        </>
      ) : (
        <ContextualFooter sx={{ height: isSilentChannel ? 64 : 34 }}>
          {userReadOnlyMode
            ? "You are in Read Only mode"
            : "You are a listener in this channel"}
          {isSilentChannel ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <VolumeOffOutlined role="presentation" sx={{ pr: 0.5 }} />
              <Typography
                sx={{ fontSize: "12px", fontStyle: "italic", fontWeight: 500 }}
              >
                Hands-free is off
              </Typography>
            </Box>
          ) : null}
        </ContextualFooter>
      )}
    </>
  );
}
