import { DrawerHeader } from "@/components/DrawerHeader";
import { useElectric } from "@/electric/ElectricWrapper";
import Locator from "@/locator";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import * as Icons from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function WorkspaceDrawerHeader() {
  const {
    isSmUp,
    toggleLeftNav,
    setRightNavOpen,
    setEditWorkspaceSettings,
    setWorkspaceModalOpen,
  } = React.useContext(UxContext);
  const { isWorkspaceLimitedMember, isWorkspaceAdmin, currentWorkspaceId, workspaces } =
    React.useContext(WorkspaceContext);
  const { myAccount } = React.useContext(MyAccountContext);
  const { ampli } = React.useContext(TrackingContext);
  const theme = useTheme();
  const { allowNewWorkspaceCreation } = useFlags();

  const { db } = useElectric();
  const navigate = useNavigate();

  const limitedMember = isWorkspaceLimitedMember();
  const workspaceAdmin = isWorkspaceAdmin();

  const { results: workspace } = useLiveQuery(() => {
    if (!currentWorkspaceId) return;
    return db.workspace.liveUnique({
      where: {
        id: currentWorkspaceId,
      },
    });
  }, [currentWorkspaceId]);

  const [workspaceAnchorEl, setWorkspaceAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [workspaceSettingsAnchorEl, setWorkspaceSettingsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const workspaceMenuOpen = Boolean(workspaceAnchorEl);
  const workspaceSettingsMenuOpen = Boolean(workspaceSettingsAnchorEl);

  const openWorkspaceMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (workspaces?.length === 1 && !allowNewWorkspaceCreation) {
      return;
    }

    setWorkspaceAnchorEl(event.currentTarget);
  };

  const closeWorkspaceMenu = () => {
    setWorkspaceAnchorEl(null);
  };

  const goToWorkspace = (ws) => {
    closeWorkspaceMenu();
    // hard refresh between workspace to force a data-dump 90% of our users will never encounter this web-873-hard-boot-workspaces-on-workspace-change
    window.location.href = `/workspaces/${ws?.id}`;
    //setTimeout(() => navigate(`/workspaces/${ws?.id}`), 200);
  };

  const openWorkspaceSettingsMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setWorkspaceSettingsAnchorEl(event.currentTarget);
  };

  const closeWorkspaceSettingsMenu = () => {
    setWorkspaceSettingsAnchorEl(null);
  };

  const goToUserManagement = useCallback(() => {
    ampli.manageUsers();
    setRightNavOpen(false);
    closeWorkspaceSettingsMenu();
    setTimeout(
      () => navigate(`/workspaces/${currentWorkspaceId}/user-management`),
      200,
    );
  }, [
    currentWorkspaceId,
    ampli,
    navigate,
    setRightNavOpen,
    closeWorkspaceSettingsMenu,
  ]);

  const openWorkspaceModal = useCallback(
    (edit) => {
      if (edit) {
        ampli.editWorkspaceName();
      } else {
        ampli.createWorkspace();
      }
      closeWorkspaceMenu();
      setEditWorkspaceSettings(edit);
      setWorkspaceModalOpen(true);
    },
    [
      ampli,
      closeWorkspaceMenu,
      setEditWorkspaceSettings,
      setWorkspaceModalOpen,
    ],
  );

  const truncatedName = (
    string: string,
    length: number,
    dots?: string,
  ): string =>
    `${string?.substring(0, length)}${string?.length >= length ? dots : ""}`;

  return (
    <>
      <DrawerHeader position="top" sx={{ p: 0 }}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            py: 2,
            gap: 1,
            width: "100%",
            height: 80,
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <Button
              variant="text"
              color="primary"
              aria-label={Locator.workspaceNav.dropdown.button}
              onClick={openWorkspaceMenu}
              sx={{
                textTransform: "none",
                gap: 1.5,
                p: 0,
                "&:hover": {
                  background: "transparent",
                },
                cursor:
                  workspaces?.length === 1 && !allowNewWorkspaceCreation
                    ? "default"
                    : "pointer",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  height: 44,
                  width: 44,
                  border: `1px solid ${theme.palette.neutral.main}`,
                }}
              >
                {workspace?.name[0] ?? ""}
              </Box>
              <Typography
                title={workspace?.name}
                variant="h6"
                component="h2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.2rem",
                  lineHeight: "1.5rem",
                  maxHeight: "72px",
                  overflow: "hidden",
                  textAlign: "left",
                }}
              >
                {workspace?.name
                  ? truncatedName(workspace?.name, 60, "...")
                  : "No Workspace"}

                {(workspaces?.length > 1 || allowNewWorkspaceCreation) && (
                  <>
                    {workspaceMenuOpen ? (
                      <Icons.KeyboardArrowUp role="img" />
                    ) : (
                      <Icons.KeyboardArrowDown role="img" />
                    )}
                  </>
                )}
              </Typography>
            </Button>
            <Menu
              anchorEl={workspaceAnchorEl}
              aria-label={Locator.workspaceNav.dropdown.list}
              open={workspaceMenuOpen}
              onClose={closeWorkspaceMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                mt: 1,
                ".MuiMenu-list": {},
              }}
              MenuListProps={{
                sx: {
                  padding: "24px 20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 2.5,
                },
              }}
            >
              {workspaces?.map((ws) => (
                <MenuItem
                  key={`workspace-${ws.id}`}
                  data-testid={ws.id}
                  onClick={() => goToWorkspace(ws)}
                  sx={{
                    gap: 1.5,
                    borderRadius: "8px",
                    p: 0,
                    pr: 1,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                      height: 44,
                      width: 44,
                      border: `1px solid ${theme.palette.neutral.main}`,
                    }}
                  >
                    {ws?.name[0]}
                  </Box>
                  <Typography
                    title={ws?.name}
                    variant="h6"
                    component="h2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1.2rem",
                      lineHeight: "1.5rem",
                      maxHeight: "72px",
                      overflow: "hidden",
                    }}
                  >
                    {truncatedName(ws?.name, 60, "...")}
                  </Typography>
                </MenuItem>
              ))}
              {!limitedMember && allowNewWorkspaceCreation ? (
                <Box sx={{ width: "100%" }}>
                  <Divider
                    sx={{
                      width: "100%",
                      m: "0 !important",
                      borderBottomWidth: "1.5px",
                      borderColor: theme.palette.secondary.main,
                    }}
                  />
                  <MenuItem
                    key={"create-workspace"}
                    arai-label={Locator.workspaceNav.dropdown.create}
                    onClick={() => openWorkspaceModal(false)}
                    sx={{
                      gap: 1.5,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                      alignSelf: "center",
                    }}
                  >
                    <Icons.AddCircleOutline />
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      New Workspace
                    </Typography>
                  </MenuItem>
                </Box>
              ) : null}
            </Menu>
          </Stack>
          {currentWorkspaceId && workspaceAdmin ? (
            <>
              <IconButton
                color="secondary"
                aria-label={Locator.workspaceNav.optionsMenu.button}
                onClick={openWorkspaceSettingsMenu}
              >
                <Icons.PendingOutlined role="img" />
              </IconButton>
              <Menu
                anchorEl={workspaceSettingsAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  mt: 1,
                }}
                open={workspaceSettingsMenuOpen}
                onClose={closeWorkspaceSettingsMenu}
              >
                <Box>
                  <MenuItem
                    onClick={goToUserManagement}
                    key="workspace-members"
                    aria-label={Locator.workspaceNav.optionsMenu.members}
                  >
                    <ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
                      Manage members
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => openWorkspaceModal(true)}
                    key="workspace-name"
                    aria-label={Locator.workspaceNav.optionsMenu.edit}
                  >
                    <ListItemText primaryTypographyProps={{ fontWeight: 500 }}>
                      Edit workspace name
                    </ListItemText>
                  </MenuItem>
                </Box>
              </Menu>
            </>
          ) : null}
        </Stack>
        {!isSmUp && (
          <IconButton
            color="primary"
            aria-label="close drawer"
            onClick={toggleLeftNav}
          >
            <Icons.Close role="img" />
          </IconButton>
        )}
      </DrawerHeader>
    </>
  );
}
