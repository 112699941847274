import AvatarWithStatus from "@/components/AvatarWithStatus";
import MemberDrawerListItem from "@/components/MemberDrawerListItem";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import { useElectric } from "@/electric/ElectricWrapper";
import { Permission, WorkspaceMembership } from "@/generated/client";
import UseTimeouts from "@/hooks/useTimeouts";
import { ActionContext } from "@/models/ActionsProvider";
import { AppContext } from "@/models/AppStateProvider";
import { DataContext } from "@/models/DataProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { UxContext } from "@/models/UxStateProvider";
import { defaultFocusStyles, menuItemFocusSX } from "@/utils";
import * as Icons from "@mui/icons-material";
import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { throttle } from "throttle-typescript";
import "../assets/menus.css";
import { DrawerHeader } from "./DrawerHeader";
import AddChannelUsersLink from "./Workspace/AddChannelUsersLink";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { FeedContext } from "@/models/FeedContextProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";

export interface MembersDrawerProps extends DrawerProps {
  handleClose: () => void;
  url: string | null;
}

const LOCATION_ENABLED = true;

export default function MembersDrawer({ sx, handleClose }: MembersDrawerProps) {
  const {
    fetchWorkspaceHandsFreeStatus,
  } = useContext(DataContext);
  const { myAccount } = useContext(MyAccountContext);
  const { currentWorkspaceId, workspaceMemberships, isWorkspaceLimitedMember } = useContext(WorkspaceContext);
  const {
    currentFeedId,
    isCurrentFeedAdmin,
    currentFeedAccounts,
    currentFeedPendingInvites,
  } = useContext(CurrentFeedContext);
  const {
    isSmUp,
    rightNavOpen,
    toggleRightNav,
    setChannelModalOpen,
    setChannelModalNew,
    setEditChannelSettings,
  } = useContext(UxContext);

  const { client } = useContext(AppContext);
  const { removeFeed } = useContext(ActionContext);
  const { db } = useElectric();
  const { handsFreeStatusIndicator, sendDesktopHandsFree } = useFlags();
  const handsFreeAllowed = handsFreeEnabledForWorkspace(currentWorkspaceId);
  const limitedMember = isWorkspaceLimitedMember();


  const handsFreeRefresh = UseTimeouts(65);
  useEffect(() => {
    if (!handsFreeAllowed || !handsFreeStatusIndicator) {
      return;
    } else if (handsFreeRefresh?.refresh === true) {
      fetchWorkspaceHandsFreeStatus();
    }
  }, [
    handsFreeAllowed,
    handsFreeStatusIndicator,
    handsFreeRefresh?.refresh,
    fetchWorkspaceHandsFreeStatus,
  ]);

  const localHeartBeat = UseTimeouts(60);
  useEffect(() => {
    console.log("MEMBERS DRAWER: localHeartBeat", localHeartBeat);
    if (!handsFreeAllowed || !sendDesktopHandsFree) return;
    if (localHeartBeat?.refresh === false) {
      if (sendDesktopHandsFree && LOCATION_ENABLED) {
        const successCallback = (position: {
          coords: {
            latitude: number;
            longitude: number;
          };
        }) => {
          const timestamp = new Date().toISOString();
          const heartbeat = {
            timestamp,
            appContext: {
              handsFreeEnabled: true,
            },
            deviceContext: {
              location: {
                latitude: position?.coords?.latitude ?? null,
                longitude: position?.coords?.longitude ?? null,
                timestamp,
              },
            },
          };
          client.heartbeat(currentWorkspaceId, heartbeat);
        };
        const errorCallback = () => {
          const timestamp = new Date().toISOString();
          const heartBeatNoGeo = {
            timestamp,
            appContext: {},
          };
          client.heartbeat(currentWorkspaceId, heartBeatNoGeo);
        };

        navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback,
        );
      } else if (sendDesktopHandsFree) {
        const timestamp = new Date().toISOString();
        const heartBeatNoGeo = {
          timestamp,
          appContext: {
            handsFreeEnabled: true,
          },
        };
        client.heartbeat(currentWorkspaceId, heartBeatNoGeo);
      }
    }
  }, [
    handsFreeAllowed,
    localHeartBeat?.refresh,
    sendDesktopHandsFree,
    currentWorkspaceId,
  ]);

  const { results: feedPermissions } = useLiveQuery(() => {
    if (!currentFeedId) {
      return;
    }
    return db.permission.liveMany({
      where: {
        feedId: currentFeedId,
      },
    });
  }, [currentFeedId]);

  const accounts = Array.from(currentFeedAccounts?.values() ?? []);

  // use the feedPermissions size to determine if accounts need to be re-rendered to reflect changes
  const activePermissionChanges = feedPermissions?.filter(
    (perm) => perm?.enabled === 1,
  )?.length;

  const accountDetails = useCallback(
    (
      accountId: string,
    ): {
      isAdmin: boolean;
      isMuted: boolean;
      workspaceMembership: WorkspaceMembership | null;
      permissions: Array<Permission>;
    } => {
      if (!workspaceMemberships || !feedPermissions) {
        return;
      }
      const findWorkspaceMembership = workspaceMemberships.find(
        (membership) => membership.accountId === accountId,
      );
      if (!findWorkspaceMembership) {
        return;
      }
      const accountPermissions = feedPermissions.filter(
        (permission) =>
          permission.workspace_membershipId === findWorkspaceMembership.id,
      );

      return {
        isAdmin: !!accountPermissions.find(
          (permission) =>
            permission?.name === "admin" && permission.enabled === 1,
        ),
        isMuted:
          accountPermissions.filter(
            (permission) =>
              permission.name === "write" && permission.enabled === 1,
          )?.length === 0 && accountPermissions?.length > 1,
        workspaceMembership: findWorkspaceMembership,
        permissions: accountPermissions,
      };
    },
    [
      activePermissionChanges,
      workspaceMemberships?.map((m) => m.id).join(),
      feedPermissions?.map((p) => p.id).join(),
    ],
  );

  const mutatedAccounts = useMemo(() => {
    return accounts?.map((account) => {
      const accountDetail = accountDetails(account?.id);
      return {
        ...account,
        permissions: accountDetail?.permissions,
        workspaceMembership: accountDetail?.workspaceMembership,
        isAdmin: accountDetail?.isAdmin,
        isMuted: accountDetail?.isMuted,
      };
    });
  }, [
    activePermissionChanges,
    accounts?.map((a) => a.id).join(),
    accountDetails,
  ]);

  const theme = useTheme();

  const [membersMenuAnchorEl, setMembersMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [membersMenuAnchorElOpen, setMembersMenuAnchorElOpen] = useState(false);
  const menuAnchorElOpen = Boolean(membersMenuAnchorEl);

  const leaveChannel = useCallback(async () => {
    await removeFeed(currentWorkspaceId, currentFeedId).then(() => toggleRightNav());
  }, [currentWorkspaceId, currentFeedId, removeFeed, toggleRightNav]);

  const handleLeaveChannel = async () => {
    setMembersMenuAnchorEl(null);
    await leaveChannel();
  };

  const { results: feed } = useLiveQuery(
    () =>
      db.feed.liveFirst({
        where: {
          id: currentFeedId,
        },
      }),
    [currentFeedId],
  );

  const canLeaveChannel = feed?.isPrivate === 1;
  const isPrivateChannel = feed?.isPrivate === 1;
  const isDm = feed?.isDm === 1;

  const _handleKeyUp = (event: KeyboardEvent<HTMLButtonElement>) => {
    const keyCode = event.key.toLowerCase();

    /**
     * @NOTE filter key presses
     *  - when initially focused, this event will fire off of `TAB`
     *  -> in order to keep the illusion that the parent element is opened,
     *     we need to throttle + filter for <space> or <enter>
     */
    if (keyCode === " " || keyCode === "enter") {
      setMembersMenuAnchorElOpen(!membersMenuAnchorElOpen);
    }
  };

  const handleKeyUp = throttle(_handleKeyUp, 100);

  const handleMemberMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setMembersMenuAnchorEl(event.currentTarget);
  };

  const handleMemberMenuClose = () => {
    setMembersMenuAnchorEl(null);
    setMembersMenuAnchorElOpen(false);
    setEditChannelSettings(false);
  };

  const handleChannelSettings = () => {
    setEditChannelSettings(true);
    setChannelModalOpen(true);
    setChannelModalNew(() => false);
  };

  /**
   * @NOTE mui drawer variants
   *  - `persistent` - drawer overlays content, blocks main content
   *      - accessing main content closes drawer
   *  - `temporary` - drawer overlaps but DOES NOT BLOCK main content
   *  - `permanent` - drawer overtakes everything
   */

  const drawerVariant = isSmUp ? "persistent" : "temporary";

  const publicChannel = !isDm && !isPrivateChannel;
  const showAddUserButton = !isDm && isPrivateChannel && isCurrentFeedAdmin;
  const showAddUsersButton =
    (publicChannel && !limitedMember) || showAddUserButton;
  const [panelId, setPanelId] = useState<string | null>(null);

  const MemberListHeader = ({
    children,
  }: {
    children: React.ReactNode;
  }) => (
    <ListSubheader
      sx={{
        p: 0,
        background: theme.palette.secondary.dark,
        width: "100%",
        zIndex: 10,
      }}
    >
      <ListItem
        sx={{
          color: theme.palette.primary.main,
          borderRadius: "8px",
          padding: 1,
          border: "1.5px solid transparent",
        }}
        component="div"
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          component="div"
        >
          {children}
        </Typography>
      </ListItem>
    </ListSubheader>
  );

  return (
    <>
      <Drawer
        variant={drawerVariant}
        sx={sx}
        anchor="right"
        open={rightNavOpen}
        onClose={handleClose}
      >
        <Paper
          sx={{
            background: theme.palette.primary.dark,
            p: 2,
            overflow: "hidden",
            height: "100svh",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              height: "100%",
            }}
          >
            <DrawerHeader position="top">
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="h6" component="h2">
                  Members
                </Typography>
                {!limitedMember &&
                  !isDm &&
                  currentFeedId &&
                  (isPrivateChannel || isCurrentFeedAdmin) && (
                    <>
                      <IconButton
                        color="secondary"
                        aria-label={"Options menu for channel"}
                        onClick={handleMemberMenuOpen}
                        onKeyUp={handleKeyUp}
                        sx={{
                          ...(menuAnchorElOpen ? defaultFocusStyles : {}),
                          color: theme.palette.primary.main,
                        }}
                      >
                        <Icons.PendingOutlined role="img" />
                      </IconButton>
                      <Menu
                        anchorEl={membersMenuAnchorEl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          mt: 1,
                          ...menuItemFocusSX,
                        }}
                        open={menuAnchorElOpen}
                        onClose={handleMemberMenuClose}
                      >
                        {isCurrentFeedAdmin && (
                          <MenuItem
                            onClick={handleChannelSettings}
                            key="channel-settings"
                          >
                            <ListItemIcon>
                              <Icons.Settings role="img" />
                            </ListItemIcon>
                            <ListItemText
                              primaryTypographyProps={{ fontWeight: 500 }}
                            >
                              Channel settings
                            </ListItemText>
                          </MenuItem>
                        )}
                        {isPrivateChannel && (
                          <MenuItem
                            disabled={!canLeaveChannel}
                            onClick={handleLeaveChannel}
                            key="leave-channel"
                          >
                            <ListItemIcon>
                              <Icons.ArrowOutward role="img" />
                            </ListItemIcon>
                            <ListItemText
                              primaryTypographyProps={{ fontWeight: 500 }}
                            >
                              Leave channel
                            </ListItemText>
                          </MenuItem>
                        )}
                      </Menu>
                    </>
                  )}
              </Stack>
              {!isSmUp && (
                <IconButton
                  color="primary"
                  onClick={handleClose}
                  aria-label="close drawer"
                >
                  <Icons.Close role="img" />
                </IconButton>
              )}
            </DrawerHeader>
            <Box
              sx={{
                height: "100%",
                background: theme.palette.secondary.dark,
                borderRadius: 4,
                overflow: "hidden",
              }}
            >
              <Stack
                sx={{
                  p: 2,
                  pr: 0.75,
                  gap: 3.75,
                  height: "100%",
                }}
              >
                {showAddUsersButton && <AddChannelUsersLink />}
                <List
                  key={`${currentWorkspaceId}-${currentFeedId}-members`}
                  className="scrollable-content"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 3.75,
                    position: "relative",
                    pt: 0,
                    "& li": {
                      width: "100%",
                    },
                    "& ul": {
                      padding: 0,
                      width: "100%",
                    },
                  }}
                >
                  <li
                    key={`section-${currentWorkspaceId}-${currentFeedId}-active-members`}
                  >
                    <ul
                      key={`${currentWorkspaceId}-${currentFeedId}-active-members-list`}
                    >
                      <MemberListHeader>
                        <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                          Active members
                        </Typography>
                      </MemberListHeader>
                      <Box>
                        {mutatedAccounts?.map((account) => {
                          return (
                            <Box
                              key={account?.id}
                              sx={{
                                display: "flex",
                                width: "100%",
                                position: "relative",
                                mb: 1,
                                p: 1,
                                alignItems: "center",
                              }}
                              className={`${account?.id === panelId && "active-parent"
                                }`}
                            >
                              <MemberDrawerListItem
                                isDm={isDm}
                                account={account}
                                myAccount={myAccount}
                                isCurrentFeedAdmin={isCurrentFeedAdmin}
                                isOpen={panelId === account?.id}
                                setDropdownState={(state) =>
                                  setPanelId(() =>
                                    state.id === panelId ? null : state.id,
                                  )
                                }
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </ul>
                  </li>
                  {currentFeedPendingInvites?.length > 0 && (
                    <li
                      key={`section-${currentWorkspaceId}-${currentFeedId}-pending-members`}
                    >
                      <ul
                        style={{ padding: 0 }}
                        key={`${currentWorkspaceId}-${currentFeedId}-pending-members-list`}
                      >
                        <MemberListHeader>
                          <Typography
                            sx={{ fontSize: "20px", fontWeight: 700 }}
                          >
                            Pending members
                          </Typography>
                        </MemberListHeader>
                        <Box>
                          {currentFeedPendingInvites?.map((invite) => {
                            return (
                              <Box
                                key={invite?.id}
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  position: "relative",
                                  mb: 1,
                                  p: 1,
                                  alignItems: "center",
                                }}
                              >
                                <ListItem
                                  className="menu-options-list"
                                  key={invite?.id}
                                  sx={{
                                    p: 1,
                                  }}
                                >
                                  <ListItemIcon>
                                    <AvatarWithStatus accountId={null} />
                                  </ListItemIcon>
                                  <ListItemText
                                    sx={{ flexGrow: 0 }}
                                    primaryTypographyProps={{
                                      fontWeight: 500,
                                    }}
                                    className="text-ellipsis"
                                  >
                                    {invite?.email || invite?.phoneNumber}
                                  </ListItemText>
                                </ListItem>
                              </Box>
                            );
                          })}
                        </Box>
                      </ul>
                    </li>
                  )}
                </List>
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Drawer>
    </>
  );
}
