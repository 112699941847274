import { Box, List, ListItemButton, Stack, useTheme } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Locator from "@/locator";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";

export default function WorkspaceTools() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const navigateToUrl = (url: string) => {
    navigate(url);
  };

  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);

  const isSelected = (key) => {
    return location?.pathname?.includes(key) || false;
  };

  const toolLinks = [
    {
      enabled: true,
      key: "workflows",
      text: "Scheduled Messages",
    },
    {
      enabled: true,
      key: "template-management",
      text: "Templates",
    },
  ]
    .filter((item) => item.enabled)
    .map((item) => ({
      ...item,
      url: `/workspaces/${workspaceId}/${item.key}`,
    }));

  return (
    <Stack
      sx={{
        flexGrow: 1,
        pb: 1.5,
        pl: 2,
        pr: 0.75,
      }}
    >
      <Box
        key={`${workspaceId}-tools-controls`}
        sx={{
          display: "flex",
          pt: 2.5,
        }}
      >
        <Box
          sx={{
            width: "100%",
            gap: 1.5,
            fontWeight: 700,
            display: "fex",
            alignItems: "center",
            fontSize: "1.25rem",
            pb: 1,
          }}
        >
          Tools
        </Box>
      </Box>
      <List key={`${workspaceId}-tools`} className="scrollable-content">
        {toolLinks?.length > 0 &&
          toolLinks.map((item) => (
            <ListItemButton
              key={item.key}
              aria-label={`Navigation to ${item.text}`}
              onClick={() => navigateToUrl(item.url)}
              selected={isSelected(item.key)}
              disableRipple={true}
              data-testid={Locator.workspaceNav.tools.options(item.key)}
              sx={{
                width: "100%",
                borderRadius: "8px",
                color: theme.palette.neutral.main,
                "&.Mui-selected": {
                  color: theme.palette.primary.main,
                  background: theme.palette.secondary.main,
                },
                justifyContent: "space-between",
                wordBreak: "break-word",
                p: 2,
                fontSize: "1.125rem",
                fontWeight: 500,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                  justifyContent: "start",
                }}
              >
                {item.text}
              </Box>
              <Box
                sx={{
                  flex: "0 1 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ChevronRightIcon />
              </Box>
            </ListItemButton>
          ))}
      </List>
    </Stack>
  );
}
